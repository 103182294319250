<template>
  <div>
    <alert
      v-if="error"
      :content="$t('error')"
      color="red"
      icon="mdi-alert-circle-outline"
    />
    <div class="layout-padding layout-container">
      <v-card class="main-page-container form-container mt-9" elevation="0">
        <inner-header mb="mb-0">
          <div
            class="justify-space-between align-center layout-padding-40 d-flex"
          >
            <div class="d-flex align-center">
              <v-btn
                color="transparent"
                class="main-header-btn pa-0 ma-0 btn-48px"
                elevation="0"
                link
                :to="`/${entity_id}/${space_id}/members`"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                    stroke="#475467"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </v-btn>

              <div class="side-head ms-6 letter-42">
                {{ $t("addMember") }}
              </div>
            </div>
            <v-btn
              color="primary"
              class="new-plus ios-r"
              elevation="0"
              @click="submitForm"
              :loading="loading"
            >
              <div class="siz-14 me-2">
                {{ $t("save") }}
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>
          </div>
        </inner-header>
        <v-card class="inner-container add-vote" elevation="0">
          <!-- <div class="mt-6 mb-6 center-header">{{ $t("member(s)Data") }}</div> -->
          <v-form class="mt-6 mb-10 border border-t-0 auth-form rounded-4">
            <addMember @memberInfo="memberInfo" />
          </v-form>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
import InnerHeader from "@/components/shared/InnerHeader";
import addMember from "@/components/ui/addMember";
import { ai } from "@/plugins/axios";
export default {
  name: "addSpaceMember",
  components: {
    InnerHeader,
    addMember,
  },
  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      error: false,
      membersToAdd: 1,
      members: [],
      roles: [],
      loading: false,
    };
  },

  methods: {
    async submitForm() {
      this.loading = true;
      try {
        await ai.post(`method/agenda.api.space.member.add_member_to_space`, {
          space_id: this.members.space_id,
          user: this.members.user,
          roles: this.members.roles,
        });
        this.loading = false;
        this.$router.push(`/${this.entity_id}/${this.space_id}/members`);
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },

    memberInfo(e) {
      this.members = e;
      console.log(this.members);
    },

    // async loadRoles() {
    //   try {
    //     const response = await ai.get(
    //       `method/agenda.api.space.role.get_space_roles`,
    //       { params: { space_id: this.space_id } }
    //     );
    //     this.roles = response.data.message.roles;
    //     this.loading = false;
    //   } catch (err) {
    //     console.log(err);
    //     this.loading = false;
    //   }
    // },
  },
  created() {
    document.title = this.$t("addMember");
    // this.loadRoles();
  },
};
</script>
