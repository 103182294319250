<template>
  <div class="auth-container position-relative">
    <div class="abos-li">
      <router-link
        @mouseenter="openTip()"
        @mouseleave="closeTip()"
        class="toolTipBtn agenda_link ms-4 smooth_rounded"
        to="/"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.9724 4.72317L20.9752 2.39062C20.8828 2.40256 20.8309 2.40874 20.7381 2.42027L18.4062 5.41808C18.4178 5.5105 18.4242 5.56237 18.4357 5.65479L21.4329 7.98734C21.5253 7.97581 21.5771 7.96923 21.6699 7.95791L24.0018 4.96009C23.9903 4.86767 23.9839 4.8158 23.9724 4.72297V4.72317Z"
            fill="#475467"
          />
          <path
            d="M13.1173 0.620805L6.30883 0L4.66515 1.6963C5.20309 3.20221 5.33233 5.3246 4.63675 6.94515C2.69673 11.466 -1.29342 17.285 0.401916 22.7895H0.396359L0.834495 24H7.4374V23.4319L7.18983 22.7477C4.12391 14.3493 4.45133 12.5456 6.30492 7.41323H6.73462L9.9697 16.3525L12.7372 24H20.3969V20.7362L13.1173 0.620805Z"
            fill="#475467"
          />
        </svg>
      </router-link>

      <tool-tip :isActive="openToolTip">
        <div class="font-weight-bold text-primary">
          <img
            loading="lazy"
            :src="require('@/assets/images/Logo.svg')"
            alt=""
          />
        </div>
        <div>
          أشرح لك كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة أشرح لك
          كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة أشرح لك كيف ولدت
          كل هذه الفكرة أشرح لك كيف ولدت كل هذه الفكرة
        </div>
      </tool-tip>
    </div>

    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import ToolTip from "@/components/ui/ToolTip";

export default {
  name: "AuthLayout",
  components: {
    ToolTip,
  },
  data() {
    return {
      openToolTip: false,
    };
  },
  methods: {
    openTip() {
      this.openToolTip = true;
    },
    closeTip() {
      this.openToolTip = false;
    },
  },
};
</script>

<style lang="scss">
.abos-li {
  position: absolute;
  // top: 55px;
  // left: 55px;
  top: 31px;
  left: 35px;
  z-index: 999;
  .toolTipBtn {
    width: 64px !important;
    height: 64px !important;
    border-radius: 0;
  }
}
</style>
