<template>
  <div
    class="main-hero-h"
    :class="{ scrolled: scrolled, img_scrolled: img_scrolled }"
  >
    <v-lazy
      class="hero-perant position-relative"
      :class="!space_id && entity_id ? 'entity_hero' : ''"
      :style="{ '--scrolled-height': scrolledHeight + 'px' }"
      transition="fade-transition"
    >
      <div class="hero-content">
        <div
          :style="{
            background:
              cover == '' ||
              cover == null ||
              cover == 'null' ||
              cover == undefined ||
              inHome
                ? color
                  ? backgroundColor
                  : backgroundColor
                : 'none',
          }"
          class="hero position-relative"
        >
          <img
            loading="lazy"
            v-if="cover"
            class="main-hero-cover"
            :src="`https://dev.agenda.42.guru/` + cover"
            alt=""
          />
        </div>

        <div
          class="d-flex algin-center justify-space-between layout-container layout-padding main-hero-data"
          :class="space_id ? 'header-back-name' : ''"
        >
          <div
            class="user-info d-flex cp"
            :class="{
              ' entity_hero':
                !space_id && entity_id && entity_type === 'personal_entity',
              ' new_class_name': !space_id && !entity_id,
            }"
          >
            <!-- back button -->
            <router-link
              v-if="space_id"
              class="maringForSummary ps-0"
              :to="`/${entity_id}`"
            >
              <v-btn class="app-btn smooth_rounded" elevation="0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#FFF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-arrow-up-right"
                >
                  <path d="M7 7h10v10" />
                  <path d="M7 17 17 7" />
                </svg>
              </v-btn>
            </router-link>
            <div
              v-if="!inHome"
              class="d-none algin-center justify-space-between"
              @click="ToggelSideInfo()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-arrow-bar-left"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#fff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 12l10 0" />
                <path d="M4 12l4 4" />
                <path d="M4 12l4 -4" />
                <path d="M20 4l0 16" />
              </svg>
              <!-- </div> -->
            </div>
            <div class="user-image smooth_rounded bx header-title-content">
              <img
                v-if="avatar != null && avatar != undefined"
                loading="lazy"
                class="smooth_rounded bx"
                :class="inHome ? '' : 'padding-user-img'"
                :src="`https://dev.agenda.42.guru/` + avatar"
                alt=""
              />
              <div
                v-else
                class="justify-center no-space-img d-flex align-center"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 26 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 0C3.10156 0 0.75 2.35156 0.75 5.25V22.75C0.75 25.6484 3.10156 28 6 28H21.75H23.5H25.25V24.5H23.5V21H25.25V0H23.5H21.75H6ZM6 21H20V24.5H6C5.03203 24.5 4.25 23.718 4.25 22.75C4.25 21.782 5.03203 21 6 21ZM7.75 7H20V8.75H7.75V7ZM20 10.5V12.25H7.75V10.5H20Z"
                    fill="#475467"
                  />
                </svg>
              </div>
            </div>

            <div
              v-if="!space_id && entity_id"
              class="header-back-name d-flex align-center"
            >
              <!-- back button -->
              <router-link class="maringForSummary ps-0" to="/launchpad">
                <v-btn class="app-btn smooth_rounded" elevation="0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#FFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-arrow-up-right"
                  >
                    <path d="M7 7h10v10" />
                    <path d="M7 17 17 7" />
                  </svg>
                </v-btn>
              </router-link>

              <div class="header-title-content d-flex align-center">
                <div
                  class="align-center d-flex gap-15 bg-white smooth_rounded index-12"
                >
                  <v-btn
                    width="40"
                    height="40"
                    class="mt-0 app-btn smooth_rounded"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.9842 3.14878L13.9861 1.59375C13.9245 1.60171 13.8899 1.60583 13.828 1.61351L12.2734 3.61205C12.2811 3.67367 12.2854 3.70825 12.2931 3.76986L14.2912 5.32489C14.3528 5.31721 14.3874 5.31282 14.4492 5.30527L16.0038 3.30673C15.9961 3.24511 15.9919 3.21053 15.9842 3.14864V3.14878Z"
                        fill="#475467"
                      />
                      <path
                        d="M8.75009 0.41387L4.21109 0L3.11531 1.13087C3.47394 2.13481 3.5601 3.54973 3.09637 4.6301C1.80303 7.64397 -0.85707 11.5233 0.273152 15.193H0.269448L0.561539 16H4.96348V15.6213L4.79843 15.1651C2.75448 9.56622 2.97276 8.36371 4.20849 4.94215H4.49495L6.65168 10.9017L8.49669 16H13.6031V13.8242L8.75009 0.41387Z"
                        fill="#475467"
                      />
                    </svg>
                  </v-btn>
                </div>
                <div class="text-white user-name ms-6">
                  {{ name }}
                </div>
              </div>
            </div>

            <div v-else class="gap-20 ms-6 user-name d-flex align-center">
              <div>{{ name }}</div>
            </div>
          </div>

          <div class="hero-content-buttons">
            <div class="gap-20 hero-buttons d-flex align-center">
              <router-link class="icone-hero smooth_rounded me-n3" to="/ask">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#FFF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-help-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                  <path d="M12 17h.01" />
                </svg>
              </router-link>
              <div
                @click="refreachPage()"
                class="icone-hero smooth_rounded me-n3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#FFF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-refresh-cw"
                >
                  <path
                    d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"
                  />
                  <path d="M21 3v5h-5" />
                  <path
                    d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"
                  />
                  <path d="M8 16H3v5" />
                </svg>
              </div>
              <router-link
                class="icone-hero smooth_rounded"
                to="/notifications"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#FFF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-bell"
                >
                  <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
                  <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
                </svg>
              </router-link>

              <div
                @click="logOut()"
                class="cursor-pointer opended-app last-used agenda-apps d-flex flex-column align-center"
              >
                <v-btn class="app-btn smooth_rounded ma-0" color="transparent">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-log-out"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                    <polyline points="16 17 21 12 16 7" />
                    <line x1="21" x2="9" y1="12" y2="12" />
                  </svg>
                </v-btn>
              </div>
              <div v-if="!inHome" class="position-relative avatar-container">
                <router-link class="" to="/">
                  <div class="user-pic cp rounded-circle">
                    <img
                      loading="lazy"
                      class="rounded-circle"
                      :src="`https://dev.agenda.42.guru/` + member_image"
                      alt=""
                      @error="handleBoardBrokenImage"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <entities-spaces-container v-if="!inHome" :openSideInfo="sideInfo" />
      </div>
    </v-lazy>
  </div>
</template>

<script>
import ToolTip from "@/components/ui/ToolTip";
import entitiesSpacesContainer from "@/components/shared/entitiesSpacesContainer.vue";
import { ai } from "@/plugins/axios";

export default {
  name: "hero",
  components: {
    ToolTip,
    entitiesSpacesContainer,
  },
  data() {
    return {
      entity_info_local: "",
      fixedHero: false,
      sideInfo: false,
      initialHeroHeight: 180, // Initial height of the hero section
      fixedHeroStep: "",
      currentValue: 20,
      refranceValue: 30,
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      member_id: "",
      unSeenNotifications: null,
      user_email: null,
      openNav: false,
      openToolTip: false,
      notifications: [],
      unSeenTasks: null,
      scheduled_meetings: [],
      tasks: [],
      memberInformation: [],
      interests: [],
      entity_info: [],
      spaces_info: [],
      name: "",
      cover: "",
      avatar: "",
      user_avatar: "",
      member_image: "",
      color: "",
      colors: [
        "#12B76A",
        // "#006EB7",
        // "#F5BC5E",
        // "#FF7449",
        // "#542344",
        // "#292524",
        // "#27233A",
        // "#8C5E58",
        // "#8955A3",
        // "#6B2737",
      ],
      backgroundColor: "",
      emails: [
        {
          text: "user@mail.com",
          spaces: [
            {
              image: require("@/assets/images/IMG_8449.jpeg"),
              title: "Solutions by 42",
              description: "عنوان اللوحة",
            },
            {
              image: require("@/assets/images/IMG_8449.jpeg"),
              title: "Solutions by 42",
              description: "عنوان اللوحة",
            },
          ],
        },
        {
          text: "user2@mail.com",
          spaces: [
            {
              image: require("@/assets/images/Screenshot from 2023-09-02 15-04-19.png"),
              title: "agenda new space",
              description: "عنوان اللوحة",
            },
          ],
        },
      ],
      onlyspace: false,
      scrolled: false,
      img_scrolled: false,
      scrolledHeight: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (
        localStorage.getItem("entity_info") &&
        this.onlyspace == true &&
        localStorage.getItem("entity_info") != undefined
      ) {
        const entityInfo = localStorage.getItem("entity_info");

        if (entityInfo) {
          try {
            const parsedEntityInfo = JSON.parse(entityInfo);
            if (parsedEntityInfo && typeof parsedEntityInfo === "object") {
              this.entity_info_local = parsedEntityInfo;
            } else {
              console.error("Parsed entity info is not a valid object.");
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          this.loadEntityInfo();
        }
      }
      this.space_id = this.$route.params.space_id;
      this.entity_id = this.$route.params.entity_id
        ? this.$route.params.entity_id
        : "";

      this.handelHeroData();

      if (this.space_id) {
        if (
          localStorage.getItem("space_info") &&
          this.$route.name != "home" &&
          this.$route.name != "entity" &&
          this.$route.name != "add-new-board"
        ) {
          let space_info = JSON.parse(localStorage.getItem("space_info"));
          if (space_info.space_id != this.space_id) {
            this.loadSpaceInfo();
          }
        }
      } else {
        this.loadEntityInfo();
      }
      if (this.sideInfo) {
        this.sideInfo = false;
      }
    },

    memberInformation: {
      handler: function (val, oldVal) {
        this.handelHeroData();
      },
      deep: true,
    },
  },

  methods: {
    refreachPage() {
      window.location();
    },
    logOut() {
      this.$store.dispatch("saveUser", null);
      localStorage.removeItem("user");
      localStorage.removeItem("jwt");
      localStorage.removeItem("user_id");
      localStorage.removeItem("entity_id");
      localStorage.removeItem("entity_info");
      localStorage.removeItem("space_id");
      localStorage.removeItem("currentRole");
      localStorage.removeItem("userRoles");
      this.$store.dispatch("setUserRole", "");
      this.$router.push("/login");
    },
    ToggelSideInfo() {
      this.sideInfo = !this.sideInfo;
    },
    goBack() {
      this.$router.go(-1);
    },
    setRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      this.backgroundColor = this.colors[randomIndex];
    },
    async loadEntityInfo() {
      try {
        const response = await ai.get(
          `method/agenda.api.entity.entity.get_entity_information`,
          { params: { entity_id: this.entity_id } }
        );
        // alert("loadEntityInfo");
        this.entity_info = response.data.message;
        localStorage.setItem(
          "entity_info",
          JSON.stringify(response.data.message.entity)
        );
        this.name = this.entity_info.company_name;
        this.avatar = this.entity_info.company_logo;
        if (this.entity_info.company_cover !== "null") {
          this.cover = this.entity_info.company_cover;
        }
        this.color = this.entity_info.company_color;
      } catch (err) {
        console.log(err);
      }
    },
    async loadSpaceInfo() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.space.get_space_details`,
          { params: { space_id: this.space_id } }
        );
        this.spaces_info = response.data.message;
        localStorage.setItem(
          "space_info",
          JSON.stringify(response.data.message)
        );
        this.name = this.spaces_info.space_name;
        this.avatar = this.spaces_info.logo;
        this.cover = this.spaces_info.cover_image;
        this.color = this.spaces_info.cover_color;

        this.loading_spaces = false;
      } catch (err) {
        console.log(err);
      }
    },
    handelHeroData() {
      // entity hero
      if (
        this.$route.name === "add-new-board" ||
        this.$route.name === "entity" ||
        this.$route.name === "editEntity" ||
        this.$route.name === "addEntityMember" ||
        this.$route.name === "addEntityNews" ||
        this.$route.name === "entityEditNews" ||
        this.$route.name === "entityViewNews" ||
        this.$route.name === "subscriptionBills" ||
        this.$route.name === "subscriptionView" ||
        this.$route.name === "payment-data" ||
        this.$route.name === "payment" ||
        this.$route.name === "add-new-board" ||
        this.$route.name === "entityNews" ||
        this.$route.name === "entityMembers" ||
        this.$route.name === "entityMemberProfile" ||
        this.$route.name === "entityMeetings" ||
        this.$route.name === "entityItems" ||
        this.$route.name === "entityFiles" ||
        this.$route.name === "entityDiscussions" ||
        this.$route.name === "entityTasksList" ||
        this.$route.name === "entityDelegations" ||
        this.$route.name === "entityRotations" ||
        this.$route.name === "entityRoles" ||
        this.$route.name === "entityLogs" ||
        this.$route.name === "entityVotes" ||
        this.$route.name === "entityRecommendations" ||
        this.$route.name === "addNewSpaceStepTwo" ||
        this.$route.name === "editEntityData" ||
        this.$route.name === "viewEntityData" ||
        this.$route.name === "addNewSpace" ||
        this.$route.name === "entitySettings" ||
        this.$route.name === "entityShareholders" ||
        this.$route.name === "entityCalender" ||
        this.$route.name === "entityTimeLine" ||
        this.$route.name === "mySpaces" ||
        this.$route.name === "entityCustomize"
      ) {
        this.onlyspace = false;

        if (
          localStorage.getItem("entity_info") &&
          localStorage.getItem("entity_info") != undefined &&
          localStorage.getItem("entity_info") != "undefined"
        ) {
          this.inHome = false;
          let entity_info = JSON.parse(localStorage.getItem("entity_info"));
          this.name = entity_info.company_name;
          this.avatar = entity_info.company_logo;
          if (entity_info.company_cover !== "null") {
            this.cover = entity_info.company_cover;
          }
          this.color = entity_info.color;
        } else {
          this.loadEntityInfo();
          this.inHome = false;
        }
        // home
      } else if (
        this.$route.name === "home" ||
        this.$route.name === "addEntity" ||
        this.$route.name === "userInfo" ||
        this.$route.name === "EditInfo" ||
        this.$route.name === "myEntities" ||
        this.$route.name === "personalSpaces" ||
        this.$route.name === "accountSettings" ||
        this.$route.name === "settings" ||
        this.$route.name === "editSettings" ||
        this.$route.name === "personalLogs" ||
        this.$route.name === "personalTasks" ||
        this.$route.name === "addPersonalSpace" ||
        this.$route.name === "profilePreview" ||
        this.$route.name === "addPersonalSpaceTwo" ||
        this.$route.name === "notifications" ||
        this.$route.name === "profileCustomize"
      ) {
        this.onlyspace = false;

        this.inHome = true;
        this.name =
          this.memberInformation.first_name +
          " " +
          this.memberInformation.last_name;
        this.avatar = this.memberInformation.user_image;
        this.cover = this.memberInformation.banner_image;
        this.member_image = this.memberInformation.user_image;
        this.color = "#12b76a";
      } else {
        this.onlyspace = true;
        if (
          localStorage.getItem("space_info") &&
          localStorage.getItem("space_info") != undefined &&
          localStorage.getItem("space_info") != "undefined"
        ) {
          this.inHome = false;
          let space_info = JSON.parse(localStorage.getItem("space_info"));
          this.name = space_info.space_name;
          this.avatar = space_info.logo;
          this.cover = space_info.cover_image;
          this.color = space_info.cover_color;
        } else {
          this.loadSpaceInfo();
          this.inHome = false;
        }
      }
    },

    handleBoardBrokenImage(e) {
      e.target.src = require("@/assets/images/brokenimg.png");
    },
    // handleSpaceBrokenImage(e) {
    //   e.target.src = require("@/assets/images/noProfilePic.jpg");
    // },
    handleBoardBrokenCover(e) {
      e.target.class = "d-none";
    },
    openTip() {
      this.openToolTip = true;
    },
    closeTip() {
      this.openToolTip = false;
    },
    close() {
      this.openNav = false;
    },
    open() {
      this.openNav = true;
    },

    async loadUserUpdates(e) {
      try {
        //  use this serves method/agenda.api.user.user.get_user_notifications and pass in body  user : this.user_id
        const response = await ai.get(
          `method/agenda.api.user.user.get_user_notifications?user=${e}`
        );
        this.notifications = response.data.message.notifications;

        this.unSeenNotifications = this.notifications.filter(
          (e) => e.seen == 0
        ).length;
        this.unSeenNotifications = unSeenNotifications;
      } catch (err) {
        // console.log(err);
      }
    },

    async loadMemberInformation(member_id) {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.user.user.get_member_information`,
          { params: { member_id: this.member_id } }
        );
        this.memberInformation = response.data.message.member_information;

        this.member_image = response.data.message.member_information.user_image;
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    handleScroll() {
      this.scrolled = window.scrollY > 60;
      this.img_scrolled = window.scrollY > 30;
      this.scrolledHeight = window.scrollY;
    },
  },

  updated() {
    this.space_id = this.$route.params.space_id;
    this.entity_id = this.$route.params.entity_id
      ? this.$route.params.entity_id
      : "";
  },
  mounted() {
    this.setRandomColor();
    this.loadMemberInformation(this.member_id);
    this.handelHeroData();
    if (
      localStorage.getItem("entity_info") &&
      this.onlyspace == true &&
      localStorage.getItem("entity_info") != undefined
    ) {
      const entityInfo = localStorage.getItem("entity_info");

      if (entityInfo) {
        try {
          const parsedEntityInfo = JSON.parse(entityInfo);
          if (parsedEntityInfo && typeof parsedEntityInfo === "object") {
            this.entity_info_local = parsedEntityInfo;
          } else {
            console.error("Parsed entity info is not a valid object.");
          }
        } catch (error) {
          // Handle JSON parsing errors
          console.error("Error parsing JSON:", error);
        }
      } else {
        this.loadEntityInfo();
      }
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  async created() {
    this.member_id = JSON.parse(localStorage.getItem("user"))?.member_id;
    this.user_email = JSON.parse(localStorage.getItem("user"))?.user_email;
  },
};
</script>

<style lang="scss">
/* Define a CSS variable */
:root {
  --scrolled-height: 100px;
}

// .scrolled {
.entity_hero {
  .hero-perant {
    min-height: 130px;
    height: calc(150px - var(--scrolled-height)) !important;
  }
  .new-header-page {
    min-height: 130px;
    height: calc(150px - var(--scrolled-height)) !important;
  }
  .hero-content {
    min-height: 130px;
    height: calc(150px - var(--scrolled-height)) !important;
    border-bottom: 4px solid #fff;

    .hero {
      min-height: 130px;
      height: calc(150px - var(--scrolled-height)) !important;
      border-bottom: 4px solid #fff;
    }

    .user-info .user-image {
      min-width: 90px;
      width: calc(140px - var(--scrolled-height)) !important;
      min-height: 90px;
      height: calc(140px - var(--scrolled-height)) !important;

      position: absolute;
      left: 50%;
      transform: translate(-50%, 50%);
      top: 13%;

      margin-top: calc(
        max(-7px, 30px - var(--scrolled-height) + 40px)
      ) !important;

      img {
        min-width: 90%;
        width: calc(90% - var(--scrolled-height)) !important;
        min-height: 90%;
        height: calc(90% - var(--scrolled-height)) !important;
      }
    }
  }
}
// }
</style>
