<template>
  <div class="layout-padding layout-container view-shareholder">
    <v-card class="main-page-container large-header-title" elevation="0">
      <inner-header mb="mb-0">
        <div
          class="justify-space-between align-center layout-padding-40 d-flex"
        >
          <div class="d-flex align-center">
            <v-btn
              color="transparent"
              class="main-header-btn pa-0 ma-0 btn-48px"
              elevation="0"
              link
              :to="`/${entity_id}/${space_id}/shareholders`"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </v-btn>

            <div class="side-head ms-6 letter-42">
              {{ $t("shareholders.view.page_title") }}
            </div>
          </div>
        </div>
      </inner-header>
      <v-card
        class="fill-width-container layout-padding-40 shareholder-container"
        elevation="0"
      >
        <!-- shareholder avatar -->
        <div class="d-flex justify-center align-center mt-9">
          <v-card class="position-relative" elevation="0">
            <div class="d-flex align-center justify-space-around">
              <v-avatar size="128">
                <img
                  width="100%"
                  loading="lazy"
                  :src="
                    `https://dev.agenda.42.guru/files/` + shareholder.user_image
                  "
                  @error="handleMemberBrokenImage"
                  alt=""
                />
              </v-avatar>
            </div>
            <div>
              <v-card-title
                class="siz-24 mb-4 mt-10 font-weight-bold text-center"
              >
                {{ shareholder?.full_name }}
              </v-card-title>
            </div>
            <!-- <div class="tag-rounded-box align-center justify-center">
              <span
                >{{
                  $t("shareholders.view.membership_id") +
                  " " +
                  shareholder.share_holder_id
                }}
              </span>
            </div> -->
            <v-card-title class="siz-20 mb-4 mt-6 font-weight-bold text-center">
              {{ $t("shareholders.view.board_basic_info") }}
            </v-card-title>
          </v-card>
        </div>
        <v-card class="border-slim-light mb-8" elevation="0">
          <table-element-box
            v-for="(element, index) in elements"
            :key="index"
            :element="element"
          />
        </v-card>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import InnerHeader from "@/components/shared/InnerHeader.vue";
import TableElementBox from "@/components/ui/TableElementBox.vue";
import { ai } from "@/plugins/axios";

// Components

export default defineComponent({
  name: "viewShareholder",
  components: {
    TableElementBox,
    InnerHeader,
  },

  data() {
    return {
      space_id: this.$route.params.space_id,
      member_id: this.$route.params.shareholder_id,
      shareholder: {},
    };
  },
  computed: {
    elements() {
      const groupNames = this.shareholder.groups?.map((group) => group.group);
      return [
        {
          label: this.$t("shareholders.view.shares_number"),
          text: this.shareholder.shares,
        },
        {
          label: this.$t("shareholders.view.shares_percentage"),
          text: this.shareholder.shares,
        },
        {
          label: this.$t("groups"),
          text: groupNames?.join(", "),
        },
      ];
    },
  },

  methods: {
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
    async getShareholderDetails() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.member.get_member_details`,
          {
            params: {
              space_id: this.space_id,
              member_id: this.member_id,
            },
          }
        );
        return response.data.message;
      } catch (err) {
        console.log(err);
      }
    },
  },

  async created() {
    this.shareholder = await this.getShareholderDetails();
    document.title = this.$t("shareholders.view.page_title");
  },
});
</script>
