<template>
  <!-- {{ localLang }}  :day-names="local.days" 
    format="E"
      :format-locale="ar"
          format="E"
              :locale="local.title"
   -->
  <VueDatePicker
    allow-prevent-default
    :position="localLang == 'ar' ? 'right' : 'left'"
    auto-apply
    :model-value="date"
    @update:model-value="setDate"
    close-on-auto-apply="true"
    type="date"
    value-format="yyyy-MM-dd"
    :time-picker="onlyTime"
    :is-24="fullTimePacker"
    closeOnAutoApply
    :auto-position="true"
    :format-locale="arLocale"
    format="E"
  >
  </VueDatePicker>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ar } from "date-fns/locale";

export default {
  components: { VueDatePicker },
  props: {
    dateOnly: {
      type: Boolean,
      default: true,
    },

    onlyTime: {
      type: Boolean,
      default: false,
    },
    fullTimePacker: {
      type: Boolean,
      default: false,
    },
    arLocale: {
      // Add a new prop for the Arabic locale
      type: Object,
      default: () => ar,
    },
  },
  data() {
    return {
      date: null,
      local: {
        title: "ar",
        days: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
      },
      localLang: this.$i18n.locale,
    };
  },

  methods: {
    setDate(date) {
      if (this.onlyTime == false) {
        const dateString = date;
        const dateObject = new Date(dateString);
        const formattedDate = dateObject.toISOString().slice(0, 10);

        const dateObj = new Date(formattedDate);
        dateObj.setDate(dateObj.getDate());
        const lastDate = dateObj.toISOString().split("T")[0];

        this.$emit("selectedDate", lastDate);
      } else {
        this.$emit("selectedTime", date);
      }
    },
  },
  mounted() {
    if (this.$i18n.locale === "en") {
      this.local = {
        title: "en",
        days: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      };
    }
  },
};
</script>

<style lang="scss">
@use "@/assets/style/base/variables.scss" as *;
.dp__overlay_row {
  justify-content: center;
}
.dp__flex_row {
  direction: ltr;
}
.dp__pm_am_button {
  background: transparent;
  padding: 0;
  margin-inline-start: 0px;
}
.dp__overlay_container {
  // overflow: hidden;
}
.dp__time_col_block {
  padding: 10px;
}

.dp__button_bottom {
  display: none;
}
.with-time {
  .dp__button_bottom {
    display: block;
  }
}
.dp__menu {
  font-family: "Vazirmatn";
  min-width: 155px !important;
  transform: translate(-109%, -100%) !important;
  // height: 110px;
}
.dp__arrow_top {
  left: 50%;
  top: 100%;
  height: 12px;
  width: 12px;
  background-color: var(--dp-background-color);
  position: absolute;
  border-right: 1px solid var(--dp-menu-border-color);
  border-bottom: 1px solid var(--dp-menu-border-color);
  transform: translate(-50%, -50%) rotate(45deg);
}
.dp__time_input {
  width: auto;
}

.time-content .dp__overlay,
.time-content .dp__instance_calendar {
  // height: 110px !important;
  width: 185px !important;
  border-radius: 8px;
}
.date-container {
  position: relative;
  // .date-table {
  .dp__main {
    // opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: transparent;

    & > div {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      background-color: transparent;
      .dp__input_wrap {
        height: 100%;

        input {
          height: 90%;
          border: 0;
          background: transparent;
          padding-inline-start: 64px;
          margin-top: 9px;
          font-size: 0;
        }

        svg {
          display: none;
        }
      }
    }
  }
  .dp__pointer {
    // opacity: 0;
  }
  // }
}

.dp__inner_nav {
  display: none;
}
.dp__today {
  border-color: $primary;
}

// set time
.time-content {
  .dp__overlay,
  .dp__instance_calendar {
    height: 130px;
    border-radius: 8px;
  }
}
.date-container .dp__main > div {
  z-index: 9990;
}
.dp__arrow_top {
  // display: none;
}
.dp__calendar_header {
  gap: 5px;
}
.dp__calendar_header_item {
  font-size: 12px;
  width: auto;
  padding: 0;
  height: auto;
  margin-bottom: 5px;
}
</style>
