<template>
  <div class="layout-padding layout-container">
    <v-card
      class="main-page-container dark-brown-color large-header-title"
      elevation="0"
    >
      <inner-header mb="mb-0">
        <div
          class="justify-space-between align-center layout-padding-40 d-flex"
        >
          <div class="d-flex align-center">
            <v-btn
              color="transparent"
              class="main-header-btn pa-0 ma-0 btn-48px"
              elevation="0"
              link
              :to="`/${entity_id}/${space_id}/members`"
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9 7.54267L11.3573 4M11.3573 4L7.81465 7.54267M11.3573 4L11.3573 8.2C11.3573 10.4091 9.56647 12.2 7.35733 12.2L1.5 12.2"
                  stroke="#475467"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-btn>

            <div class="side-head ms-6 letter-42">
              {{ $t("memberProfile") }}
            </div>
          </div>
        </div>
      </inner-header>

      <SkeletonRow v-if="loading" />

      <v-card v-else class="inner-container" elevation="0">
        <!-- personal info -->
        <div class="d-flex justify-center align-center flex-column mt-9">
          <img
            loading="lazy"
            class="profile-img"
            :src="`https://dev.agenda.42.guru/` + memberInformation.user_image"
            alt=""
            @error="handleMemberBrokenImage"
          />
          <div class="siz-24 mt-6 font-weight-bold">
            {{
              memberInformation.first_name + " " + memberInformation.last_name
            }}
          </div>
          <div class="mt-4">
            {{ memberInformation.bio }}
          </div>
        </div>

        <!-- satrt expentions boxes -->
        <v-card elevation="0" class="d-flex mt-4 mb-8">
          <v-card elevation="0" width="100%" class="pa-0">
            <!-- basic Data -->
            <div class="expansion-center-container">
              <v-expansion-panels
                class="expansion-circle bg-transparent"
                elevation="0"
                v-model="open1"
              >
                <v-expansion-panel
                  color="transparent"
                  class="bg-transparent dark-brown-color"
                  elevation="0"
                >
                  <v-expansion-panel-title
                    hide-actions
                    class="d-flex gap-20 bg-transparent justify-start ps-0 pa-0 my-3"
                    elevation="0"
                  >
                    <v-btn elevation="0" class="icone-arrow"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                          stroke="#0A0A0A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </v-btn>
                    <div class="expansion-label">
                      {{ $t("basicData") }}
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="mb-8">
                    <div class="border-slim-light">
                      <div class="inline-data-container">
                        <div>{{ $t("first_name") }}</div>
                        <div class="font-weight-500">
                          {{ memberInformation.first_name }}
                        </div>
                      </div>
                      <div class="inline-data-container">
                        <div>{{ $t("last_name") }}</div>
                        <div class="font-weight-500">
                          {{ memberInformation.last_name }}
                        </div>
                      </div>
                      <div
                        v-if="
                          memberInformation?.national_id &&
                          memberInformation?.national_id !== null
                        "
                        class="inline-data-container"
                      >
                        <div>{{ $t("ID") }}</div>
                        <div class="font-weight-500">
                          {{ memberInformation.national_id }}
                        </div>
                      </div>
                      <div
                        v-if="
                          memberInformation?.date_of_birth_hijri !== null &&
                          memberInformation?.date_of_birth_hijri !== '0'
                        "
                        class="inline-data-container"
                      >
                        <div>{{ $t("dateOfBirthHijri") }}</div>
                        <div class="font-weight-500">
                          {{
                            formatHijriDate(
                              memberInformation.date_of_birth_hijri
                            )
                          }}
                        </div>
                      </div>
                      <div
                        v-if="
                          memberInformation?.date_of_birth_gregorian !== null
                        "
                        class="inline-data-container"
                      >
                        <div>{{ $t("dateOfBirthGregorian") }}</div>
                        <div class="font-weight-500">
                          {{
                            formatDate(
                              memberInformation.date_of_birth_gregorian
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <!-- contact Data -->
            <div class="expansion-center-container">
              <v-expansion-panels
                class="expansion-circle bg-transparent"
                elevation="0"
              >
                <v-expansion-panel
                  color="transparent"
                  class="bg-transparent"
                  elevation="0"
                >
                  <v-expansion-panel-title
                    hide-actions
                    class="d-flex gap-20 bg-transparent justify-start ps-0 pa-0 my-3"
                    elevation="0"
                  >
                    <v-btn elevation="0" class="icone-arrow"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                          stroke="#0A0A0A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </v-btn>
                    <div class="expansion-label">
                      {{ $t("contact") }}
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="mb-8">
                    <div class="border-slim-light">
                      <contact-box
                        v-if="memberInformation?.phone"
                        :hrefType="tel"
                        :link="memberInformation?.phone"
                        :img="'phone.svg'"
                        :label="$t('phone')"
                      />
                      <contact-box
                        v-if="memberInformation?.member_id"
                        :hrefType="mail"
                        :link="memberInformation?.member_id"
                        :img="'mail.svg'"
                        :label="$t('email')"
                      />
                      <contact-box
                        v-if="memberInformation?.twitter_link"
                        :link="memberInformation?.twitter_link"
                        :img="'twitterBlue.svg'"
                        :label="$t('linkFor') + ' ' + $t('twitter')"
                      />

                      <contact-box
                        v-if="memberInformation?.linkedin_link"
                        :link="memberInformation?.linkedin_link"
                        :img="'LinkedIn_icon.svg'"
                        :label="$t('linkFor') + ' ' + $t('linkedin')"
                      />
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <!-- interstes -->
            <div
              v-if="memberInformation?.interest !== null"
              class="expansion-center-container"
            >
              <v-expansion-panels
                class="expansion-circle bg-transparent"
                elevation="0"
              >
                <v-expansion-panel
                  color="transparent"
                  class="bg-transparent dark-brown-color"
                  elevation="0"
                >
                  <v-expansion-panel-title
                    hide-actions
                    class="d-flex gap-20 bg-transparent justify-start ps-0 pa-0 my-3"
                    elevation="0"
                  >
                    <v-btn elevation="0" class="icone-arrow"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                          stroke="#0A0A0A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </v-btn>
                    <div class="expansion-label">
                      {{ $t("interstes") }}
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="mb-8">
                    <div class="border-slim-light">
                      <div class="interest-box pa-5">
                        <div
                          class="interest-box-title font-weight-regular text-body-2"
                        >
                          {{ $t("interstes") }}
                        </div>
                        <div class="interest-box-desc">
                          {{ memberInformation.interest }}
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <!-- Memberships -->
            <!-- <div class="expansion-center-container">
              <v-expansion-panels
                class="expansion-circle bg-transparent"
                elevation="0"
              >
                <v-expansion-panel
                  color="transparent"
                  class="bg-transparent"
                  elevation="0"
                >
                  <v-expansion-panel-title
                    hide-actions
                    class="d-flex gap-20 bg-transparent justify-start ps-0 pa-0 my-3"
                    elevation="0"
                  >
                    <v-btn elevation="0" class="icone-arrow"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.7969 14.3996L11.9969 9.59961L7.19688 14.3996"
                          stroke="#0A0A0A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </v-btn>
                    <div class="expansion-label">
                      {{ $t("memberships") }}
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <v-card class="mt-6" elevation="0">
                      <MemberShipTimeline
                        v-for="board in boards"
                        :key="board[0].space_id"
                        :boardInfo="board"
                      />
                      <MemberShipTimeline :boards="boards" />
                    </v-card>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div> -->
          </v-card>
        </v-card>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";

// Components
import SkeletonRow from "@/components/ui/rows/SkeletonRow.vue";
import InnerHeader from "@/components/shared/InnerHeader";
import ContactBox from "@/components/ui/ContactBox";
import interestedTag from "@/components/ui/interestedTag";
import MemberShipTimeline from "@/components/ui/MemberShipTimeline";
import { ai } from "@/plugins/axios";

export default defineComponent({
  name: "memberProfile",
  components: {
    SkeletonRow,
    InnerHeader,
    ContactBox,
    interestedTag,
    MemberShipTimeline,
  },

  data() {
    return {
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      all_interests: [],
      open1: [0],
      loading: false,
      member_id: this.$route.params.id,
      memberInformation: {},
      interests: [],
      boards: [],
    };
  },

  methods: {
    async loadInterests() {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.home.member.get_all_interests`
        );
        this.loading = false;
        this.all_interests = response.data.message.interests;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    async loadMemberInformation(member_id) {
      try {
        this.loading = true;
        const response = await ai.get(
          `method/agenda.api.user.user.get_member_information`,
          { params: { member_id: member_id } }
        );
        this.memberInformation = response.data.message.member_information;
        this.interests = response.data.message.interests;

        // this.boards = this.groupBy(response.data.message.boards, "space_id");
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    groupBy(arr, prop) {
      if (arr.length == 0) return [];
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
      arr.forEach((obj) => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },
    formatDate(timestamp) {
      if (timestamp == undefined || timestamp == "") return "";
      const date = new Date(timestamp);
      return date.toLocaleString("ar", { dateStyle: "long" });
    },
    formatHijriDate(hijriDate) {
      if (hijriDate == undefined || hijriDate == "") return "";
      const hijriMonthNames = [
        "محرم",
        "صفر",
        "ربيع الأول",
        "ربيع الثاني",
        "جمادى الأولى",
        "جمادى الثانية",
        "رجب",
        "شعبان",
        "رمضان",
        "شوال",
        "ذو القعدة",
        "ذو الحجة",
      ];

      // Convert the Hijri date string to a Date object
      const [year, month, day] = hijriDate.split("-").map(Number);
      const hijriDateObj = new Date(Date.UTC(year, month - 1, day));

      // Format the date using the Arabic Hijri month names
      const dayNumber = hijriDateObj.getUTCDate();
      const monthName = hijriMonthNames[hijriDateObj.getUTCMonth()];
      const yearNumber = hijriDateObj.getUTCFullYear();

      return `${dayNumber} ${monthName} ${yearNumber}`;
    },
    handleMemberBrokenImage(e) {
      e.target.src = require("@/assets/images/noProfilePic.jpg");
    },
  },
  mounted() {
    this.loadInterests();
  },
  async created() {
    document.title = this.$t("memberProfile");
    await this.loadMemberInformation(this.member_id);
  },
});
</script>
