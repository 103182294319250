<template>
  <div class="layout-padding layout-container">
    <v-card
      class="main-page-container form-container small-header-title"
      elevation="0"
    >
      <inner-header mb="mb-0">
        <div
          class="justify-space-between align-center layout-padding-40 d-flex"
        >
          <div class="d-flex align-center">
            <v-btn
              color="transparent"
              class="main-header-btn pa-0 ma-0 btn-48px"
              elevation="0"
              link
              :to="`/${entity_id}/${space_id}/shareholders`"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8307 4.16699L4.16406 15.8337M15.8307 15.8337L4.16406 4.16699"
                  stroke="#475467"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </v-btn>

            <div class="side-head ms-6 letter-42">
              {{ $t("editShareholder") }}
            </div>
          </div>
          <v-btn
            color="primary"
            class="new-plus ios-r"
            elevation="0"
            :loading="loading"
            @click="submitForm"
          >
            <div class="siz-14 me-2">
              {{ $t("save") }}
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6693 4.7666L6.7255 14.7666L3.33594 11.3579"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
        </div>
      </inner-header>
      <v-card class="inner-container add-vote py-0" elevation="0">
        <v-card class="d-flex my-10" elevation="0">
          <div class="form-content form-vertical-center w-100">
            <v-card class="form-auth pa-0" elevation="0" width="100%">
              <v-form
                v-model="valid"
                ref="form"
                class="news-form form-inputs-container slim-form border-0"
              >
                <div class="">
                  <v-row :key="index" class="ma-0 border-light rounded-t-4">
                    <v-col class="pa-0" cols="9" md="9" sm="9">
                      <v-text-field
                        v-model="form.member_id"
                        :label="$t('shareholders.add.email_label')"
                        :placeholder="$t('shareholders.add.email_placeholder')"
                        class="form-input-box bg-transparent rounded-4 border-0"
                        dense
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        solo
                        type="email"
                        variant="solo"
                        :rules="emailRules"
                      ></v-text-field>
                    </v-col>
                    <v-col class="border-s pa-0" cols="3" md="3" sm="3">
                      <v-text-field
                        v-model="form.shares"
                        :label="$t('shareholders.add.number_of_shares_label')"
                        :placeholder="
                          $t('shareholders.add.number_of_shares_placeholder')
                        "
                        class="form-input-box bg-transparent"
                        dense
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        solo
                        variant="solo"
                        type="number"
                        :rules="rules"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col class="border-s pa-0" cols="3" md="3" sm="3">
                      <v-text-field
                        v-model="shareholder.percentageOfShares"
                        :label="
                          $t('shareholders.add.percentage_of_shares_label')
                        "
                        :placeholder="
                          $t(
                            'shareholders.add.percentage_of_shares_placeholder'
                          )
                        "
                        class="form-input-box bg-transparent border-0"
                        dense
                        hide-details="auto"
                        outlined
                        persistent-placeholder
                        readonly
                        solo
                        variant="solo"
                        type="text"
                        :rules="rules"
                      ></v-text-field>
                    </v-col> -->
                    <v-col class="border-t pa-5" cols="12" md="12" sm="12">
                      <div class="flex-10g">
                        <button
                          v-for="group in groups"
                          :key="group.group_id"
                          class="select-type-btn"
                          :class="{ active: isSelected(group.group_id) }"
                          @click="toggleSelection(group.group_id)"
                          type="button"
                        >
                          {{ group.group }}
                        </button>
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-btn
                    height="56px"
                    class="border-light w-100 rounded-0 rounded-4-bottom text-blue-gray d-flex justify-center align-center mt-6 dark-brown-color white-svg"
                    elevation="0"
                    @click="addShareholder()"
                  >
                    <svg
                      fill="none"
                      height="24"
                      viewBox="0 0 25 24"
                      width="25"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5031 7.19995L12.5031 16.8M17.3031 12L7.70312 12"
                        stroke="#475467"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      />
                    </svg>
                    {{ $t("shareholders.add.add_more_shareholder") }}
                  </v-btn> -->
                </div>
              </v-form>
            </v-card>
          </div>
        </v-card>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import InnerHeader from "@/components/shared/InnerHeader.vue";
import { ai } from "@/plugins/axios";

export default defineComponent({
  name: "editShareholder",
  components: {
    InnerHeader,
  },
  data() {
    return {
      entity_id: this.$route.params.entity_id,
      space_id: this.$route.params.space_id,
      member_id: this.$route.params.shareholder_id,
      loading: false,
      valid: true,
      shareholders: [
        {
          email: "",
          numberOfShares: "",
          percentageOfShares: "",
        },
      ],
      selectedGroups: [],
      rules: [(v) => !!v || this.$t("pleaseFilloutThisField")],
      emailRules: [
        (v) => !!v || this.$t("mail_must_be_valid"),
        (v) => /.+@.+\..+/.test(v) || this.$t("mail_must_be_valid"),
      ],
      form: {
        member_id: "",
        shares: null,
        groups: [
          {
            group_id: "",
          },
        ],
      },
      groups: [],
    };
  },
  props: {},
  methods: {
    async getShareholderDetails() {
      try {
        const response = await ai.get(
          `method/agenda.api.space.member.get_member_details`,
          {
            params: {
              space_id: this.space_id,
              member_id: this.member_id,
            },
          }
        );
        const details = response.data.message;
        this.form.member_id = details.member_id;
        this.form.shares = details.shares;
        this.selectedGroups = details.groups;
      } catch (err) {
        console.log(err);
      }
    },
    async loadGroups() {
      try {
        const response = await ai.get(
          "method/agenda.api.space.group.get_all_groups",
          {
            params: {
              space_id: this.space_id,
            },
          }
        );
        this.groups = response.data.message;
      } catch (error) {
        console.log(error);
      }
    },
    addShareholder() {
      this.shareholders.push({
        email: "",
        numberOfShares: "",
        percentageOfShares: "",
      });
    },
    isSelected(groupId) {
      return this.selectedGroups.some((group) => group.group_id === groupId);
    },
    toggleSelection(groupId) {
      const index = this.selectedGroups.findIndex(
        (group) => group.group_id === groupId
      );

      if (index === -1) {
        this.selectedGroups.push({ group_id: groupId });
      } else {
        this.selectedGroups.splice(index, 1);
      }
    },
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        try {
          await ai.put(`method/agenda.api.space.member.update_shareholder`, {
            space_id: this.space_id,
            member_id: this.form.member_id,
            shares: this.form.shares,
            groups: this.selectedGroups,
          });
          this.loading = false;
          this.$router.push(`/${this.entity_id}/${this.space_id}/shareholders`);
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
  },
  created() {
    document.title = this.$t("shareholders.add.page_title");
  },
  mounted() {
    this.loadGroups();
    this.getShareholderDetails();
  },
});
</script>
